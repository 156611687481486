import React from 'react';
import styled from 'styled-components';
import TopArea from './top-area';
import BottomBar from './bottom-bar';

const Footer = ({ className }) => (
  <footer className={className}>
    <TopArea />
    <BottomBar />
  </footer>
);

export default styled(Footer)`
  background-color: #003366;
  color: white;
  font-size: 14px;
  line-height: 25px;
`;
