import styled, { css } from 'styled-components';
import PlusIcon from 'gatsby-theme-wordpress/src/components/icons/plus-icon';

export default styled(PlusIcon)`
  display: block;
  transition: transform .3s linear;
  ${(props) => props.active && css`
    transform: rotate(45deg);
  `}
`;
