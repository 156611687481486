import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import Wrap from '../wrap';
import Menu from './menu';

const MainHeader = ({ className }) => {
  const language = useLanguage();
  const {
    logo,
  } = useStaticQuery(graphql`
    query Header {
      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          fixed(width: 264) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className={className}>
      <Wrap>
        <SpacerForHamburger />
        <Link to={`/${language}/`}>
          <Img
            fixed={logo?.childImageSharp?.fixed}
            alt="logo"
            loading="eager"
            fadeIn={false}
          />
        </Link>
        <Menu />
      </Wrap>
    </div>
  );
};

const SpacerForHamburger = styled.div`
  width: 28px;
`;

export default styled(MainHeader)`
  ${Wrap} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 990px) {
    ${SpacerForHamburger} {
      display: none;
    }
  }
`;
