import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import activeClassName from './active-class-name';

const StyledLink = styled(({
  isHovering,
  isMouseEntered,
  ...props
}) => <Link {...props} />)`
  text-decoration: none;
  color: #003366;
  display: block;
  &.${activeClassName}.${activeClassName} {
    color: #88b526;
  }
  @media screen and (min-width: 990px) {
    ${(props) => props.isMouseEntered && css`
      color: #88b526;
    `};
  }
`;

export const TopLevelLink = styled(StyledLink)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 30px 16px 0;

  @media screen and (min-width: 990px) {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    padding: 16px 4px;
    margin: 20px 4px;
    @media screen and (min-width: 1200px) {
      font-size: 17px;
      margin: 20px 14px;
    }

    position: relative;
    &::before {
      position: absolute;
      left: 0;
      bottom: 10px;
      height: 2px;
      width: 100%;
      background: rgba(0, 0, 0, .1);
      content: '';
      opacity: 0;
      transition: opacity .3s, transform .3s;
      transform: translateY(10px);
      background-color: #88b526;
    }

    &.${activeClassName} ${(props) => props.isHovering && ', &'} {
      &::before {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

export default StyledLink;
