import styled, { css } from 'styled-components';

export default styled.div`
  max-width: 100%;
  width: 1300px;
  text-align: center;
  margin: 0 auto;

  ${(props) => !props.noMobilePadding && css`
    padding: 0 20px;
  `}

  @media only screen and (min-width: 768px) {
    text-align: left;
    padding: 0 50px;
  }
`;
