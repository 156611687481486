import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Wrap from './wrap';
import Breadcrumbs from './breadcrumbs';

export const pageQuery = graphql`
  fragment Fancyheader on wordpress__wp_media {
    localFile {
      name
      childImageSharp {
        fluid(maxWidth: 2400, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Fancyheader = ({
  image,
  title,
  breadcrumbs,
}) => {
  if (!image?.localFile?.childImageSharp?.fluid) {
    return (
      <NoImage>
        <Wrap>
          <Title>{title}</Title>
          <Breadcrumbs breadcrumbs={breadcrumbs} title={title} />
        </Wrap>
      </NoImage>
    );
  }
  return (
    <FancyBackgroundImage
      Tag="section"
      fluid={image.localFile.childImageSharp.fluid}
    >
      <Overlay />
      <FancyWrap>
        <Title>{title}</Title>
        <Breadcrumbs breadcrumbs={breadcrumbs} title={title} />
      </FancyWrap>
    </FancyBackgroundImage>
  );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const FancyWrap = styled(Wrap)`
  min-height: 70px;
  z-index: 1;
  position: relative;

  justify-content: center;
  flex-flow: column wrap;
  display: flex;
  align-items: center;

  box-sizing: border-box;

  @media screen and (min-width: 778px) {
    align-items: flex-start;
    min-height: 500px;
  }
`;

const Title = styled.h1`
  padding: 5px 0;
  margin: 5px 0;
  max-width: 100%;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color: #ffffff;
  text-transform: none;
`;

const FancyBackgroundImage = styled(BackgroundImage)`
  background-color: #222;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;

  border-bottom: 35px solid #eeee22;
  border-top: 35px solid #003366;
`;

const NoImage = styled.div`
  border-bottom: 10px solid #f4e200;
  background-color: #003366;
  padding-top: 25px;
  padding-bottom: 20px;
`;

export default Fancyheader;
