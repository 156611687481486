import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import NavItem from './nav-item';

export const pageQuery = graphql`
  fragment MenuItems on wordpress__wp_api_menus_menus_items {
    items {
      id: wordpress_id
      url
      title
      order
      target
      children: wordpress_children {
        id: wordpress_id
        url
        title
        order
        target
        children: wordpress_children {
          id: wordpress_id
          slug: object_slug
          title
          order
          target
          url
        }
      }
    }
  }
`;

const Menu = ({ className }) => {
  const language = useLanguage();
  const {
    menus,
  } = useStaticQuery(graphql`
    query Drawer {
      menus: allWordpressWpApiMenusMenusItems(filter: {
        slug: { eq: "main-menu" }
      }) {
        edges {
          node {
            language
            ...MenuItems
          }
        }
      }
    }
  `);
  const menu = menus.edges?.find(({ node }) => node.language === language);

  return (
    <nav className={className}>
      {(menu?.node.items || []).sort((a, b) => a.order > b.order).map((item, index) => (
        <NavItem
          key={item.id}
          {...item}
          isFirst={index === 0}
          level={0}
        />
      ))}
    </nav>
  );
};

export default styled(Menu)`
  @media screen and (max-width: 989px) {
    transition: right 0.2s ease;
    transform: translate3d(100%, 0, 0);
    background-color: white;
    position: fixed;
    z-index: 1002;
    top: 0;
    height: 100%;
    min-height: 100%;
    padding: 30px 30px 0;
    overflow: hidden;
    overflow-y: auto;
    right: 0;
    width: 300px;
    ${(props) => props.isOpen && css`
      right: 300px;
    `}
    text-align: left;
  }
  @media screen and (min-width: 990px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
