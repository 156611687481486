import React from 'react';
import SEO from 'gatsby-theme-wordpress/src/components/seo';
import GlobalStyle from './global-style';
import Header from './header';
import Footer from './footer';
import Fancyheader from './fancyheader';

const Layout = ({
  children,
  page,
  noFancyHeader = false,
  noMainPadding = false,
}) => (
  <>
    <GlobalStyle />
    <SEO
      yoast_meta={page.yoast_meta}
      yoast_title={page.yoast_title}
      yoast_json_ld={page.yoast_json_ld}
    />
    <Header />
    {!noFancyHeader && (
      <Fancyheader
        image={page?.fancyheader}
        breadcrumbs={page?.breadcrumbs}
        title={page?.title}
      />
    )}
    <main css={!noMainPadding ? 'padding: 70px 0' : null}>{children}</main>
    <Footer />
  </>
);

export default Layout;
