import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'gatsby-theme-wordpress/src/context/location';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import Button from 'gatsby-theme-wordpress/src/components/button';
import ChevronRight from 'gatsby-theme-wordpress/src/components/icons/chevron-right';
import activeClassName from './active-class-name';
import Link, { TopLevelLink } from './link';
import PlusIcon from './plus-icon';
import Drawer from './drawer';

const NavItem = ({
  isParentOpen,
  url,
  title,
  target,
  children,
  level,
  isFirst,
}) => {
  const language = useLanguage();
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  // if the parent gets closed, close this
  useEffect(() => {
    if (!isParentOpen) {
      setOpen(false);
    }
  }, [isParentOpen]);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  // set the menu open when we enter this page
  useEffect(() => {
    if (location.pathname.startsWith(url)) {
      setOpen(true);
    }
  }, [url, location]);

  // use a ref, because we need to mutate it realtime
  // useState would have old state saved inside the function
  const [isHovering, setHovering] = useState(false);
  const [isHoveringDirty, setHoveringDirty] = useState(false);
  const [isMouseEntered, setMouseEntered] = useState(false);
  const mouseEnteredRef = useRef();

  const handleEnter = () => {
    // container to know that we entered the nav item
    mouseEnteredRef.current = true;
    setMouseEntered(true);
    setHovering(true);
    setHoveringDirty(true);
  };

  const handleLeave = () => {
    // container to know that we left the nav item
    mouseEnteredRef.current = false;
    setMouseEntered(false);
    const doClose = () => {
      // when we still left the nav item after the timeout, close the nav
      if (!mouseEnteredRef.current) {
        setHovering(false);
      }
    };
    setTimeout(doClose, 100);
  };

  const LinkComponent = level === 0 ? TopLevelLink : Link;

  return (
    <StyledNavItem
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      isMouseEntered={isMouseEntered}
      isHovering={isHovering}
      isFirst={isFirst}
      level={level}
    >
      <LinkComponent
        to={url}
        target={target}
        partiallyActive={url !== `/${language}/`}
        activeClassName={activeClassName}
        isMouseEntered={isMouseEntered}
        isHovering={isHovering}
      >
        {title}
      </LinkComponent>
      {children && (
        <>
          <Button onClick={handleToggle}>
            <PlusIcon active={isOpen} />
            <ChevronRight />
          </Button>
          <Drawer
            items={children}
            isOpen={isOpen}
            isMouseEntered={isMouseEntered}
            isHoveringDirty={isHoveringDirty}
            isHovering={isHovering}
            level={level}
            ItemComponent={NavItem}
          />
        </>
      )}
    </StyledNavItem>
  );
};

const StyledNavItem = styled.div`
  @media screen and (max-width: 989px) {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    ${(props) => !props.isFirst && props.level === 0 && css`
      border-top: 1px solid rgba(0, 51, 102, 0.08);
    `}

    ${Link} {
      flex-grow: 1;
    }
    ${PlusIcon} {
      color: rgba(0, 51, 102, 0.27);
    }
    ${Button} {
      padding-left: 15px;
      > ${PlusIcon} {
        width: 15px;
      }
      > ${ChevronRight} {
        display: none;
      }
    }
  }
  @media screen and (min-width: 990px) {
    position: relative;
    display: flex;
    align-items: center;
    > ${Link} {
      flex-grow: 1;
    }

    ${(props) => props.level > 0 && props.isMouseEntered && css`
      background-color: rgba(136,181,38,0.07);
    `}

    ${(props) => props.level === 0 && css`
      > ${Button} {
        display: none;
      }
    `}
    > ${Button} {
      padding-left: 12px;
      > ${PlusIcon} {
        display: none;
      }
      > ${ChevronRight} {
        width: 6px;
        ${(props) => props.isMouseEntered && css`
          color: #88b526;
        `}
      }
    }
  }
`;

export default NavItem;
