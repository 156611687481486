import styled, { css } from 'styled-components';

export const style = css`
  cursor: pointer;
  display: block;
  color: #fff;
  background-color: #88b526;
  border-radius: 3px;
  border: 0px solid #88b526;
  padding: 12px 18px 12px 18px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
`;

export default styled.button.attrs({
  type: 'submit',
  role: 'submit',
})`
  ${style}
`;
