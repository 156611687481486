import styled, { css } from 'styled-components';
import Input from './input';

const svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="rgba(133,134,140,0.5)" x="0px" y="0px" width="8px" height="8px" viewBox="0 0 386.257 386.257" style="enable-background:new 0 0 386.257 386.257;" xml:space="preserve"><polygon points="0,96.879 193.129,289.379 386.257,96.879 "/></svg>'
  .replace(/\(/g, '%28')
  .replace(/\)/g, '%29');

export const style = css`
  font-size: 14px;
  line-height: 25px;
  background-image: url('data:image/svg+xml, ${svg}');
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  appearance: none;
`;

export default styled(Input)`
  ${style}
`;
