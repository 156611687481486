import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font: normal 15px / 27px Arial, Helvetica, Arial, Verdana, sans-serif;
    word-spacing: normal;
    color: #000000;
    padding: 0;
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }
  h2 {
    font: normal 600 34px / 44px Arial, Helvetica, Arial, Verdana, sans-serif;
    color: #303030;
    font-weight: bold;
  }
`;

export default GlobalStyle;
