import React from 'react';
import styled from 'styled-components';
import TopBar from './top-bar';
import MainHeader from './main-header';
import Menu from './menu';
import Wrap from '../wrap';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${TopBar}, ${MainHeader} {
    width: 100%;
  }

  @media screen and (min-width: 990px) {
    max-width: 100%;
    width: 1300px;
    margin: 0 auto;
    padding: 0 50px;

    flex-wrap: nowrap;
    ${TopBar} {
      order: 2;
    }
    ${MainHeader} {
      order: 1;
      flex-grow: 1;
      ${Menu} {
        width: 100%;
      }
    }
    ${TopBar}, ${MainHeader} {
      width: unset;
      ${Wrap} {
        width: unset;
        padding: unset;
        max-width: unset;
      }
    }
  }
`;

const Header = ({ className }) => (
  <div className={className}>
    <Wrapper>
      <TopBar />
      <MainHeader />
    </Wrapper>
  </div>
);

export default styled(Header)`
  @media screen and (min-width: 990px) {
    position: sticky;
    top: 0;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.07);
    background-color: white;
    z-index: 1000;
  }
`;
