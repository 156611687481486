import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { useI18n, useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import ChevronRight from 'gatsby-theme-wordpress/src/components/icons/chevron-right';
import Wrap from '../wrap';
import ContactForm from '../contact-form';

const activeClassName = 'footer-nav-active';

const Form = styled(ContactForm)`
  form input[type=submit] {
    margin: 0 auto;
    @media screen and (min-width: 778px) {
      margin: 0;
    }
  }
`;

const TopArea = ({ className }) => {
  const language = useLanguage();
  const i18n = useI18n();
  const {
    menus,
    logo,
    reach,
    gmp,
    options,
  } = useStaticQuery(graphql`
    query FooterNav {
      menus: allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "footer" }}) {
        edges {
          node {
            language
            items {
              id: wordpress_id
              url
              title
              order
              target
              children: wordpress_children {
                id: wordpress_id
                url
                title
                order
                target
              }
            }
          }
        }
      }
      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          fixed(width: 182) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      reach: file(name: { eq: "reach" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      gmp: file(name: { eq: "GMP" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      options: allWordpressPage(filter: {
        template: { eq: "templates/template-homepage.php" }
      }) {
        edges {
          node {
            wpml_current_locale
            acf {
              text: footer_text
              form: contact_us_form
              locations: contact_locations {
                phone
                email
              }
            }
          }
        }
      }
    }
  `);
  const menu = menus.edges?.find(({ node }) => node.language === language);
  const option = options.edges?.find(({ node }) => node.wpml_current_locale === language);

  return (
    <div className={className}>
      <Wrap>
        <Inner>
          <div css="> * { margin: 10px 0 }">
            <Link to={`/${language}/`} css="display: block">
              <Img fixed={logo?.childImageSharp?.fixed} alt="logo" />
            </Link>
            <div>
              {option?.node?.acf?.text}
            </div>
            <div>
              <span className="color-primary">{i18n?.phone}:</span><br />
              <a href={`tel:${(option?.node?.acf?.locations || [])[0]?.phone?.replace(/\s/g, '')}`}>
                {option?.node?.acf?.locations?.[0]?.phone}
              </a>
            </div>
            <div>
              <span className="color-primary">{i18n?.email}:</span><br />
              <a href={`mailto:${option?.node?.acf?.locations?.[0]?.email}`}>
                {option?.node?.acf?.locations?.[0]?.email}
              </a>
            </div>
            <div>
              <Img fixed={reach?.childImageSharp?.fixed} alt="reach" />
            </div>
            <div>
              <Img fixed={gmp?.childImageSharp?.fixed} alt="logo" />
            </div>
          </div>
          <>
            {(menu?.node?.items || []).sort((a, b) => a.order > b.order).map(({
              id,
              url,
              title,
              target,
              children,
            }) => (
              <nav key={id}>
                <TitleLink
                  to={url}
                  target={target}
                  title={title}
                >
                  <span>{title}</span>
                </TitleLink>
                <ChildrenList>
                  {(children || []).sort((a, b) => a.order > b.order).map((child) => (
                    <li key={child.id}>
                      <ChildLink
                        to={child.url}
                        target={child.target}
                        title={child.title}
                        partiallyActive={url !== `/${language}/`}
                        activeClassName={activeClassName}
                      >
                        {child.title}
                      </ChildLink>
                      <ChildLinkIcon>
                        <ChevronRight />
                      </ChildLinkIcon>
                    </li>
                  ))}
                </ChildrenList>
              </nav>
            ))}
          </>
          <div>
            <TitleLink as="div">{i18n?.contactUs}</TitleLink>
            <Form small htmlString={option?.node?.acf?.form} />
          </div>
        </Inner>
      </Wrap>
    </div>
  );
};

const Inner = styled.div`
  > * {
    padding-bottom: 25px;
  }
  @media screen and (min-width: 778px) {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    > :nth-child(4) {
      grid-column: 1 / 4;
    }
  }
  @media screen and (min-width: 990px) {
    grid-template-columns: repeat(4, 1fr);
    > :nth-child(4) {
      grid-column: unset;
    }
  }
`;

const TitleLink = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  display: block;
`;

const ChildLinkIcon = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ChevronRight} {
    width: 6px;
  }
`;

const ChildLink = styled(Link)`
  padding-left: 8px;
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;

  &.${activeClassName} {
    color: #88b526;
    + ${ChildLinkIcon} {
      ${ChevronRight} {
        color: rgba(136, 181, 38, 0.999);
      }
    }
  }

  &:hover {
    color: #88b526;
  }
`;

const ChildrenList = styled.ul`
  list-style: none;
  padding: 0 0 10px;
  margin: 0;

  li:not(:first-child) {
    margin-top: 9px;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 778px) {
      justify-content: left;
    }

    ${ChildLink} {
      order: 2;
    }

    ${ChildLinkIcon} {
      order: 1;
    }

    ${ChevronRight} {
      color: rgba(255, 255, 255, 0.5);
    }
    &:hover {
      ${ChevronRight} {
        color: rgba(136, 181, 38, 0.999);
      }
    }
  }
`;

export default styled(TopArea)`
  padding-top: 60px;
  padding-bottom: 60px;

  a:not(${ChildLink}) {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
