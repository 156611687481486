import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

export const pageQuery = graphql`
  fragment Breadcrumbs on wordpress__PAGE {
    breadcrumbs: parent_element {
      status
      link
      title
      parent: parent_element {
        status
        link
        title
        parent: parent_element {
          status
          link
          title
        }
      }
    }
  }
`;

const Parent = ({ page }) => page?.status === 'publish' && (
  <>
    <Link to={page.link}>
      {page.title}
    </Link>
    <span> / </span>
  </>
);

const Breadcrumbs = ({ className, breadcrumbs, title }) => {
  const language = useLanguage();
  return (
    <nav className={className}>
      <Link to={`/${language}/`}>Home</Link>
      <span> / </span>
      <Parent page={breadcrumbs?.parent?.parent} />
      <Parent page={breadcrumbs?.parent} />
      <Parent page={breadcrumbs} />
      {title}
    </nav>
  );
};

export default styled(Breadcrumbs)`
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 23px;
  color: #fff;

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  span {
    padding: 0 6px;
  }

  display: none;
  @media screen and (min-width: 778px) {
    display: block;
  }
`;
