import React from 'react';
import styled from 'styled-components';
import LanguageSwitch from 'gatsby-theme-wordpress/src/components/language-switch';
import Wrap from '../wrap';

const TopBar = ({ className }) => (
  <div className={className}>
    <Wrap>
      <LanguageSwitch />
    </Wrap>
  </div>
);

export default styled(TopBar)`
  font-size: 14px;
  line-height: 36px;

  ${Wrap} {
    display: flex;
    justify-content: center;
    align-item: center;
  }
`;
