import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import Wrap from '../wrap';

const BottomBar = ({ className }) => {
  const language = useLanguage();
  const {
    menus,
  } = useStaticQuery(graphql`
    query BottomFooterNav {
      menus: allWordpressWpApiMenusMenusItems(filter: {
        slug: { eq: "bottom-footer" }
      }) {
        edges {
          node {
            language
            items {
              id: wordpress_id
              url
              title
              order
              target
            }
          }
        }
      }
    }
  `);
  const menu = menus.edges?.find(({ node }) => node.language === language);

  return (
    <div className={className}>
      <Wrap>
        <Flex>
          <div>© LignoStar. All rights reserved 2004 - {new Date().getFullYear()}</div>
          <div>
            {(menu?.node?.items || []).sort((a, b) => a.order > b.order).map(({
              id,
              url,
              title,
              target,
            }) => (
              <Link
                key={id}
                to={url}
                target={target}
                title={title}
              >
                {title}
              </Link>
            ))}
          </div>
        </Flex>
      </Wrap>
    </div>
  );
};

const Flex = styled.div`
  padding: 10px;
  min-height: 60px;

  @media screen and (min-width: 990px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default styled(BottomBar)`
  background-color: #88b526;
  font-size: 13px;
  line-height: 23px;
  color: #ffffff;

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    &:not(:first-child)::before {
      content: " | ";
      display: inline-block;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
`;
