import React, { useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import Hamburger from 'gatsby-theme-wordpress/src/components/icons/hamburger';
import Button from 'gatsby-theme-wordpress/src/components/button';
import CloseIcon from './close-icon';
import Drawer from './drawer';

const GlobalStyle = createGlobalStyle`
  body {}
`;

const Overlay = styled.div`
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;

  ${(props) => props.isOpen && css`
    opacity: 1;
    visibility: visible;
  `}
`;

const Menu = ({ className }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={className}>
      {isOpen && <GlobalStyle />}
      <Button onClick={() => setOpen(!isOpen)}>
        <Hamburger />
      </Button>

      <Overlay
        isOpen={isOpen}
        onClick={() => setOpen(false)}
      />
      <Drawer
        isOpen={isOpen}
      />
      <Button onClick={() => setOpen(!isOpen)}>
        <CloseIcon
          isOpen={isOpen}
        >
          <span />
        </CloseIcon>
      </Button>
    </div>
  );
};

export default styled(Menu)`
  ${Hamburger} {
    width: 28px;
    color: #003366;
  }
  @media screen and (min-width: 990px) {
    > ${Button} {
      display: none;
    }
  }
`;
