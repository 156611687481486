import styled, { css } from 'styled-components';

export const style = css`
  color: #85868c;
  border: 1px solid rgba(170, 170, 170, 0.25);
  background-color: #fdfdfd;
  border-radius: 0px;
  padding: 5px 15px 5px 15px;
  outline: 0;
  width: 100%;
  line-height: 25px;
  font-size: 14px;
`;

export default styled.input`
  ${style}
`;
