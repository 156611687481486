import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PlusIcon from 'gatsby-theme-wordpress/src/components/icons/plus-icon';
import Button from 'gatsby-theme-wordpress/src/components/button';
import Link from './link';

const Drawer = ({
  className,
  level,
  items,
  isOpen,
  ItemComponent,
}) => (
  <div className={className}>
    {(items || []).sort((a, b) => a.order > b.order).map((item, index) => (
      <ItemComponent
        key={item.id}
        {...item}
        isParentOpen={isOpen}
        isFirst={index === 0}
        level={level + 1}
      />
    ))}
  </div>
);

export const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;
export const fadeOut = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`;

export const grow = keyframes`
  from {
    font-size: 0px;
    line-height: 0px;
  }
  to {
    font-size: 13px;
    line-height: 17px;
  }
`;
export const shrink = keyframes`
  from {
    font-size: 13px;
    line-height: 17px;
  }
  to {
    font-size: 0px;
    line-height: 0px;
  }
`;

export const fadeInMargin = keyframes`
  from {
    margin-bottom: 0px;
  }
  to {
    margin-bottom: 16px;
  }
`;
export const fadeOutMargin = keyframes`
  from {
    margin-bottom: 16px;
  }
  to {
    margin-bottom:0px;
  }
`;

export const fadeInLink = keyframes`
  from {
    padding: 0px;
  }
  to {
    padding: 6.5px 0px 6.5px 0;
  }
`;
export const fadeOutLink = keyframes`
  from {
    padding: 6.5px 0px 6.5px 0;
  }
  to {
    padding: 0px;
  }
`;

export const fadeInIcon = keyframes`
  from {
    width: 0px;
  }
  to {
    width: 9px;
  }
`;
export const fadeOutIcon = keyframes`
  from {
    width: 9px;
  }
  to {
    width: 0px;
  }
`;

// props:
// level: boolean
// isOpen: boolean
export default styled(Drawer)`
  ${css`
    @media screen and (max-width: 989px) {
      width: 100%;
      margin-left: 20px;
      transform-origin: top;

      font-size: 0;
      line-height: 0;

      animation-duration: 0.2s;
      animation-timing-function: ease-out;
      animation-name: ${shrink} ${(props) => props.level === 0 && css`, ${fadeOutMargin}`};

      > div > ${Link} {
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-name: ${fadeOutLink};
        padding: 0;
      }
      > div > ${Button} > ${PlusIcon}${PlusIcon} {
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-name: ${fadeOutIcon};
        width: 0px;
      }

      ${(props) => props.isOpen && css`
        font-size: 13px;
        line-height: 17px;

        animation-timing-function: ease-in;
        animation-name: ${grow} ${props.level === 0 && css`, ${fadeInMargin}`};
        ${props.level === 0 && css`margin-bottom: 16px;`}

        > div > ${Link} {
          animation-timing-function: ease-in;
          animation-name: ${fadeInLink};
          padding: 6.5px 0px 6.5px 0;
        }
        > div > ${Button} > ${PlusIcon}${PlusIcon} {
          animation-timing-function: ease-in;
          animation-name: ${fadeInIcon};
          width: 9px;
        }
      `}
    }

    @media screen and (min-width: 990px) {
      position: absolute;
      top: 100%;
      left: 0;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      background-color: white;
      padding: 10px 10px 10px 10px;
      width: 280px;

      visibility: hidden;
      opacity: 0;
      animation-duration: 0.1s;
      /* if we dont do this, the animation will run on mount */
      ${(props) => props.isHoveringDirty && css`
        animation-name: ${fadeOut};
        animation-timing-function: ease-out;
      `}
      ${(props) => props.isHovering && css`
        visibility: visible;
        opacity: 1;
        animation-name: ${fadeIn};
        animation-timing-function: ease-in;
      `}

      > div > ${Link} {
        margin: 0px 0px 0px 0px;
        padding: 10px 10px 10px 10px;
        transition: background 0.3s;
        font-size: 13px;
        line-height: 17px;
        text-decoration: none;
        text-transform: none;
      }

      ${(props) => props.level > 0 && css`
        top: -10px;
        left: 271px;
      `}
    }
  `}
`;
