import React from 'react';
import styled, { css } from 'styled-components';
import useForm from 'gatsby-theme-wordpress/src/components/form/useForm';
import { useI18n } from 'gatsby-theme-wordpress/src/context/language';
import { style as inputStyle } from './form/input';
import { style as selectStyle } from './form/select';
import { style as submitStyle } from './form/submit';

const Alert = styled.span`
  display: block;
  padding: 15px 0;
  ${(props) => props.success && css`
    color: green;
  `}
  ${(props) => props.error && css`
    color: red;
  `}
`;

const FormWrapper = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
    text-align: left;

    > * {
      width: 100%;
      margin: 10px 0;
      ${(props) => props.small && css`
        margin: 5px 0;
      `}
    }

    ${(props) => !props.small && css`
      .SELECT, .INPUT {
        width: 50%;
        padding-right: 2.6%;
        &:nth-child(2n) {
          padding-right: 0;
        }
      }
    `}

    h1 {
      font-weight: normal;
    }

    label {
      font-weight: 700;
      > span {
        display: block;
        margin-top: 10px;
      }
      ${(props) => props.small && css`
        font-size: 0;
        br {
          display: none;
        }
        > span {
          display: inline;
        }
      `}
    }


    input[type=submit] {
      ${submitStyle}
      ${(props) => props.submitting && css`
        opacity: 0.5;
      `}
    }

    select,
    input:not([type=submit]),
    textarea {
      ${inputStyle}
    }

    ${(props) => props.small && css`
      textarea {
        height: 100px;
      }
    `}

    select {
      ${selectStyle}
    }
  }
`;

const ContactForm = ({
  className,
  small,
  htmlString,
}) => {
  const {
    ref,
    submitting,
    error,
    success,
  } = useForm();
  const i18n = useI18n();

  return (
    <section
      className={className}
    >
      <FormWrapper
        small={small}
        submitting={submitting}
        ref={ref}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
      {error && (
        <Alert error>{i18n?.formError}</Alert>
      )}
      {success && (
        <Alert success>{i18n?.formSuccess}</Alert>
      )}
    </section>
  );
};

export default styled(ContactForm)`
  ${(props) => !props.small && css`
    border-top: 1px solid #EBEBEB;
    padding-top: 15px;
  `}
`;
