import styled, { css } from 'styled-components';

export default styled.div`
  right: 0;
  position: fixed;
  z-index: 1003;
  top: 0;
  width: 30px;
  height: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease;
  transition-delay: .2s;

  ${(props) => props.isOpen && css`
    opacity: 1;
    visibility: visible;
  `}

  > span {
    color: #fff;
    background-color: #88b526;
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;
    &::before {
      transform: rotate3d(0, 0, 1, 45deg);
      display: inline-block;
      height: 2px;
      background: #fff;
      position: absolute;
      content: '';
      transform-origin: 50% 50%;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -11px;
      width: 22px;
      transition: transform .1s ease;
    }
    &::after {
      transform: rotate3d(0, 0 , 1,-45deg);
      display: inline-block;
      height: 2px;
      background: #fff;
      position: absolute;
      content: '';
      transform-origin: 50% 50%;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -11px;
      width: 22px;
      transition: transform .1s ease;
    }
  }
`;
